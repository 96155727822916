import { Card, CardContent, Grid, styled } from '@material-ui/core'
import {
  FloatingMenu,
  FormikCheckbox,
  FormikFormSectionTitle,
  FormikInputField,
  MenuItem,
  Select,
  Typography
} from '@ui/stickybid'
import { Field, FieldArray } from 'formik'
import React, { useEffect, useState } from 'react'
import getHighestRole from '../../util/getHighestRole'
import UserImportExport from './UserImportExport'

export interface UserAdministrationFormProps {
  section?: string

  onSectionChange?: (section: string) => any
}

export default function UserAdministrationForm({ section = 'general', onSectionChange }: UserAdministrationFormProps) {
  const [menuValue, setMenuValue] = useState(section)

  // update if url changes
  useEffect(() => {
    setMenuValue(section)
  }, [section])

  function handleMenuItemClick(item) {
    if (onSectionChange) {
      onSectionChange(item.key)
    }
    setMenuValue(item.key)
  }

  const menuItems = [
    { key: 'general', label: 'General' },
    { key: 'features', label: 'Features' },
    { key: 'importExport', label: 'Import/Export' }
  ]

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} xl={2}>
        <FloatingMenu
          value={menuValue}
          defaultValue="general"
          items={menuItems}
          onItemClick={handleMenuItemClick}
          collapseOn="lg"
        />
      </Grid>
      <Grid item xs={12} xl={10}>
        <Card>
          <CardContent>
            {menuValue === 'general' && <GeneralForm />}
            {menuValue === 'features' && <FeaturesForm />}
            {menuValue === 'importExport' && <UserImportExport />}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

function GeneralForm() {
  return (
    <>
      <Typography variant="h5">General</Typography>
      <SectionContent>
        <Grid container>
          <Field name="user.app_metadata.active" label={'Active'} component={FormikCheckbox} />
        </Grid>
      </SectionContent>
      <Typography variant="h5">Role</Typography>
      <SectionContent>
        <Grid container>
          <FieldArray
            name="user.app_metadata.roles"
            render={(arrayHelpers) => (
              <Select
                margin="dense"
                value={getHighestRole(arrayHelpers.form.values.user.app_metadata.roles)}
                variant="outlined"
                onChange={(e) => {
                  arrayHelpers.form.setFieldValue('user.app_metadata.roles', [e.target.value])
                }}
              >
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="sales">Sales</MenuItem>
                {/* <MenuItem value="crew">Crew</MenuItem> */}
                {/* <MenuItem value="viewer">Viewer</MenuItem> */}
              </Select>
            )}
          />
        </Grid>
      </SectionContent>
      <Typography variant="h5" style={{ marginBottom: 8 }}>
        Salesmate ID
      </Typography>
      <SectionContent>
        <Grid container>
          <Field name="preferences.salesmateId" component={FormikInputField} />
        </Grid>
      </SectionContent>
    </>
  )
}

function FeaturesForm() {
  return (
    <Grid>
      <Grid item xs={12}>
        <Field title="ElasticSearch" name="user.app_metadata.elasticsearch" component={FormikFormSectionTitle} />
      </Grid>
    </Grid>
  )
}

const SectionContent = styled('div')({
  paddingLeft: 20,
  paddingRight: 20
})
