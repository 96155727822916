import type {
  PresentationOption,
  PresentationPage,
  PresentationPageOption,
  PresentationPageOptionCategory,
  PresentationStyle,
  QuoteFile
} from 'paintscout'
import React, { useState, useRef, useMemo } from 'react'
import type { Theme } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import { createStyles, withStyles } from '@material-ui/core'
import type { DialogProps } from '@ui/stickybid'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  Typography,
  useClientOptions
} from '@ui/stickybid'
import {
  createPresentationOption,
  createPresentationPage,
  getDefaultPresentationImages,
  getPresentationPageOptions,
  replacePresentationImages,
  uuid
} from '@paintscout/util/builder'
import { CloseButton } from '../../../dialogs/common'
import classnames from 'classnames'
import { PresentationImbedPreview } from '../../../presentation'
import { makeStyles } from '@material-ui/core'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import MenuIcon from '@material-ui/icons/Menu'
import AddIcon from '@material-ui/icons/Add'
import { PRESENTATION_LABEL } from '@paintscout/util/constants'

export interface PresentationPageSelectDialogProps extends Omit<DialogProps, 'message' | 'title' | 'loading'> {
  classes?: DialogProps['classes'] & StyleClasses<typeof styles>
  onConfirm: (page: PresentationPage) => void
  presentation: PresentationOption
  onCancel?: () => void
}

const styles = (theme: Theme) =>
  createStyles({
    dialogPaper: {
      minHeight: 500
    },
    menu: {
      position: 'fixed'
    },
    tileContainer: {
      display: 'flex',
      margin: theme.spacing(0, -1.5)
    },
    tileCol: {
      flex: '50%',
      maxWidth: '50%',
      margin: theme.spacing(0, 1.5)
    },
    menuItem: {
      display: 'block',
      textDecoration: 'none'
    },
    menuItemActive: {
      textDecoration: 'underline'
    },
    menuSpacer: {
      margin: theme.spacing(2, 0)
    },
    list: {},
    helpAlert: {
      '& p': {
        marginBottom: theme.spacing(1),
        '&:last-child': {
          marginBottom: 0
        }
      }
    },
    inlineIcon: {
      fontSize: '1.25rem',
      marginRight: 2,
      position: 'relative',
      top: 4
    }
  })

function PresentationPageSelectDialog({
  open,
  classes,
  onConfirm,
  presentation,
  onCancel,
  ...props
}: PresentationPageSelectDialogProps) {
  const { options } = useClientOptions()
  const [category, setCategory] = useState<PresentationPageOptionCategory | null>('banner')

  const [selectedPage, setSelectedPage] = useState<null | PresentationPageOption>(null)
  const [hideHelp, setHideHelp] = useState(window.localStorage.getItem('hidePageSelectHelp') === 'true')
  const handleHideHelp = () => {
    window.localStorage.setItem('hidePageSelectHelp', 'true')
    setHideHelp(true)
  }

  const allPageOptions = useMemo(() => getPresentationPageOptions({ options }), [])
  const pageOptions = allPageOptions.filter((pageOption) => pageOption.categories.includes(category))

  const selected = !!selectedPage
  const images = useMemo(() => getDefaultPresentationImages({ options, presentation }), [])
  const style = presentation.advanced.style
  const halfwayThrough = Math.ceil(pageOptions.length / 2)
  const pageOptionsCol1 = pageOptions.slice(0, halfwayThrough)
  const pageOptionsCol2 = pageOptions.slice(halfwayThrough, pageOptions.length)

  const contentRef = useRef(null)
  const handleChangeCategory = (category: PresentationPageOptionCategory) => {
    setCategory(category)
    contentRef?.current.scrollTo(0, 0)
  }

  return (
    <Dialog
      open={open}
      classes={!selected ? { paper: classes.dialogPaper } : null}
      fullWidth
      maxWidth={!selected ? 'xl' : 'md'}
      {...props}
    >
      <DialogTitle rightContent={<CloseButton onCancel={onCancel} />}>
        {!selected ? 'Add Block' : 'Block Details'}
      </DialogTitle>
      <DialogContent ref={contentRef}>
        {!selected && (
          <Grid container>
            <Grid item xs={2}>
              <div className={classes.menu}>
                <MenuItem
                  className={classnames({
                    [classes.menuItem]: true,
                    [classes.menuItemActive]: category === 'banner'
                  })}
                  label={'Banner'}
                  value={'banner'}
                  onClick={(val) => handleChangeCategory(val)}
                />
                <MenuItem
                  className={classnames({
                    [classes.menuItem]: true,
                    [classes.menuItemActive]: category === 'about'
                  })}
                  label={'About'}
                  value={'about'}
                  onClick={(val) => handleChangeCategory(val)}
                />
                <MenuItem
                  className={classnames({
                    [classes.menuItem]: true,
                    [classes.menuItemActive]: category === 'feature'
                  })}
                  label={'Features'}
                  value={'feature'}
                  onClick={(val) => handleChangeCategory(val)}
                />
                <MenuItem
                  className={classnames({
                    [classes.menuItem]: true,
                    [classes.menuItemActive]: category === 'testimonial'
                  })}
                  label={'Testimonials'}
                  value={'testimonial'}
                  onClick={(val) => handleChangeCategory(val)}
                />
                <MenuItem
                  className={classnames({
                    [classes.menuItem]: true,
                    [classes.menuItemActive]: category === 'gallery'
                  })}
                  label={'Gallery'}
                  value={'gallery'}
                  onClick={(val) => handleChangeCategory(val)}
                />
                <MenuItem
                  className={classnames({
                    [classes.menuItem]: true,
                    [classes.menuItemActive]: category === 'team'
                  })}
                  label={'Team'}
                  value={'team'}
                  onClick={(val) => handleChangeCategory(val)}
                />
                <MenuItem
                  className={classnames({
                    [classes.menuItem]: true,
                    [classes.menuItemActive]: category === 'documents'
                  })}
                  label={'Documents'}
                  value={'documents'}
                  onClick={(val) => handleChangeCategory(val)}
                />
                <MenuItem
                  className={classnames({
                    [classes.menuItem]: true,
                    [classes.menuItemActive]: category === 'footer'
                  })}
                  label={'Footer'}
                  value={'footer'}
                  onClick={(val) => handleChangeCategory(val)}
                />
                <div className={classes.menuSpacer}></div>
                <MenuItem
                  className={classnames({
                    [classes.menuItem]: true,
                    [classes.menuItemActive]: category === 'text'
                  })}
                  label={'Text'}
                  value={'text'}
                  onClick={(val) => handleChangeCategory(val)}
                />
                <MenuItem
                  className={classnames({
                    [classes.menuItem]: true,
                    [classes.menuItemActive]: category === 'media'
                  })}
                  label={'Media'}
                  value={'media'}
                  onClick={(val) => handleChangeCategory(val)}
                />
                <MenuItem
                  className={classnames({
                    [classes.menuItem]: true,
                    [classes.menuItemActive]: category === 'mediaAndText'
                  })}
                  label={'Media and Text'}
                  value={'mediaAndText'}
                  onClick={(val) => handleChangeCategory(val)}
                />
                <div className={classes.menuSpacer}></div>
                <MenuItem
                  className={classnames({
                    [classes.menuItem]: true
                  })}
                  label={'Empty'}
                  onClick={() =>
                    setSelectedPage(
                      createPresentationPage({
                        presentationPageOption: {
                          type: 'empty',
                          title: 'New Block',
                          label: 'New Block',
                          sections: []
                        }
                      })
                    )
                  }
                />
              </div>
            </Grid>
            <Grid item xs={10} className={classes.list}>
              <>
                {!hideHelp && (
                  <Alert variant={'outlined'} severity={'info'} onClose={handleHideHelp} className={classes.helpAlert}>
                    <Typography variant={'body1'}>
                      {`Each Block you add will become an item in the Navigation Menu. Your clients can access this menu
                      by clicking`}{' '}
                      <MenuIcon className={classes.inlineIcon} />{' '}
                      {`in the Navigation Bar located at the top
                      of the ${PRESENTATION_LABEL}.`}
                    </Typography>
                    <Typography variant={'body1'}>
                      You can add Sections within a Block by clicking the <AddIcon className={classes.inlineIcon} />{' '}
                      icon at the top and bottom of the purple / blue Section outlines.
                    </Typography>
                  </Alert>
                )}
                <div className={classes.tileContainer}>
                  <div className={classes.tileCol}>
                    {pageOptionsCol1.map((page, index) => {
                      return (
                        <PagePreview
                          key={page.sections[0].key}
                          setSelectedPage={setSelectedPage}
                          pageOption={page}
                          images={images}
                          style={style}
                        />
                      )
                    })}
                  </div>
                  <div className={classes.tileCol}>
                    {pageOptionsCol2.map((page, index) => {
                      return (
                        <PagePreview
                          key={page.sections[0].key}
                          setSelectedPage={setSelectedPage}
                          pageOption={page}
                          images={images}
                          style={style}
                        />
                      )
                    })}
                  </div>
                </div>
              </>
            </Grid>
          </Grid>
        )}
        {selected && (
          <div>
            <Input
              autoFocus
              label="Block Title"
              value={selectedPage.label}
              onChange={(ev) => setSelectedPage({ ...selectedPage, label: ev.target.value, title: ev.target.value })}
              fullWidth={true}
              required
              inputProps={{ maxLength: 20 }}
            />
            {/* <FormControlLabel
              label={'Include in Navigation'}
              control={
                <Checkbox
                  checked={selectedPage.advanced.useForNav}
                  onChange={(ev: React.ChangeEvent) =>
                    setSelectedPage({
                      ...selectedPage,
                      advanced: { ...selectedPage.advanced, useForNav: !selectedPage.advanced.useForNav }
                    })
                  }
                />
              }
            /> */}
            {/* {!hideHelp && (
              <Alert variant={'outlined'} severity={'info'} onClose={handleHideHelp}>
                If "Include in Navigation" is checked this block's title is added to the navigation to help your
                customers find it easily.
              </Alert>
            )} */}
          </div>
        )}
      </DialogContent>
      {selected && (
        <DialogActions
          leftButton={
            <Button onClick={() => setSelectedPage(null)} prominence={3}>
              {'< Back'}
            </Button>
          }
        >
          <Button
            onClick={() => {
              onConfirm(createPresentationPage({ presentationPageOption: insertTitle(selectedPage) }))
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

export default withStyles(styles)(PresentationPageSelectDialog)

function insertTitle(pageOption: PresentationPageOption): PresentationPageOption {
  if (!pageOption.advanced.titleContent || !pageOption.advanced.titleContentField) {
    return pageOption
  } else {
    const newPageOption = cloneDeep(pageOption)
    set(
      newPageOption,
      pageOption.advanced.titleContentField,
      pageOption.advanced.titleContent.replace('INSERT_TITLE', pageOption.label)
    )
    return newPageOption
  }
}

function replaceImages(args: { presentation: PresentationOption; images: QuoteFile[] }): PresentationOption {
  const { presentation, images } = args
  return replacePresentationImages({ presentation, images })
}

const useStyles = makeStyles<Theme>((theme) => ({
  presPreview: {
    transform: 'scale(0.5)',
    transformOrigin: 'top left',
    width: '200%',
    pointerEvents: 'none'
  },
  pageOption: {
    marginBottom: theme.spacing(3),
    '&:last-child': {
      marginBottom: 0
    },
    border: `1px solid ${theme.palette.grey[500]}`,
    cursor: 'pointer',
    overflow: 'hidden',
    '&:hover': {
      borderColor: theme.palette.grey[800]
    }
  },
  pageTile: {
    overflow: 'hidden',
    transition: 'transform 400ms ease',
    '&:hover': {
      transform: 'scale(1.05)'
    }
  }
}))

function PagePreview(props: {
  pageOption: PresentationPageOption
  setSelectedPage: React.Dispatch<React.SetStateAction<PresentationPageOption>>
  style: PresentationStyle
  images: QuoteFile[]
}) {
  const { setSelectedPage, pageOption, style, images } = props
  const { options } = useClientOptions()
  const initPresentation = createPresentationOption({ options })
  const classes = useStyles(props)
  const page = createPresentationPage({ presentationPageOption: cloneDeep(pageOption) })
  const [height, setHeight] = useState(0)

  const presentation = replaceImages({
    presentation: {
      ...initPresentation,
      key: uuid(),
      advanced: {
        ...initPresentation.advanced,
        style: style
      },
      pages: [page]
    },
    images: images
  })

  const handleSelect = () => {
    const selectedPage = {
      ...presentation.pages[0],
      sections: presentation.pages[0].sections.map((section) => {
        return {
          ...section,
          key: uuid()
        }
      })
    }
    setSelectedPage(selectedPage)
  }

  return (
    <div onClick={handleSelect} className={classes.pageOption}>
      <div className={classes.pageTile} style={{ height: height / 2 }}>
        <PresentationImbedPreview
          classes={{
            root: classnames({
              [classes.presPreview]: true
            })
          }}
          border={'none'}
          loadingStyle={'none'}
          presentation={presentation}
          preview={'none'}
          autoHeight
          autoHeightCallback={setHeight}
          hideNav
          hideFooter
          preventScroll
        />
      </div>
    </div>
  )
}

function MenuItem(args: {
  label: string
  value?: PresentationPageOptionCategory
  onClick: (value: PresentationPageOptionCategory) => void
  className: string
}) {
  const { label, value, onClick, className } = args
  return (
    <Button className={className} edge={'start'} prominence={3} onClick={() => onClick(value)}>
      {label}
    </Button>
  )
}
