import type { OptionsDocument, PresentationOption } from 'paintscout'
import { getPrefabImages } from '../../../../database/fixtures/sb/presentations/prefabImages'
import shuffle from 'lodash/shuffle'

export function getDefaultPresentationImages(args: { options: OptionsDocument; presentation: PresentationOption }) {
  const { options, presentation } = args

  if (presentation.advanced.defaultImages && presentation.advanced.defaultImages.length > 0) {
    return shuffle(presentation.advanced.defaultImages)
  } else {
    return getPrefabImages({
      options,
      industry: presentation.advanced.industry || (options.options.companyIndustry.value as string)
    })
  }
}
