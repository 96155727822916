import React, { useState } from 'react'
import type { PresentationOption, PresentationPage } from 'paintscout'

import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { ConfirmationDialog, MenuItem, PopupMenu, PresEditorTooltip, useDialogs } from '@ui/stickybid'
import { AddButton } from '@ui/stickybid/src/ButtonPopupMenu'
import { updatePresentationPage, deletePresentationPage, pastePresentationPage } from '@paintscout/util/builder'

import EditOutlineIcon from '@material-ui/icons/EditOutlined'
import AddIcon from '@material-ui/icons/Add'
import { BlocksIcon, NoFlagIcon, PasteIcon } from '@ui/core/icons'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { useFormikContext } from 'formik'
import { usePresentation } from '../../context'
import { EditPageTemplateDialog, PresentationPageSelectDialog } from '../dialogs'
import cloneDeep from 'lodash/cloneDeep'
import { useSnackbar } from 'notistack'
import classnames from 'classnames'
import { PRESENTATION_LABEL } from '@paintscout/util/constants'

const useStyles = makeStyles<Theme, PageMenuProps>(
  (theme) => ({
    root: {},
    menuItemDivider: {
      padding: 0,
      height: 0,
      minHeight: 0,
      paddingBottom: theme.spacing(),
      marginBottom: theme.spacing(),
      borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    menuToggleWrapper: {
      position: 'relative',
      height: theme.spacing(8),
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      color: theme.palette.secondary.main,
      '&:before, &:after': {
        content: '""',
        borderBottom: `1px dashed ${theme.palette.secondary.main}`
      },
      '&:before': {
        flexBasis: '20px',
        margin: '0 20px 0 calc(-0.5 * (100vw - 1370px))',
        ['@media (max-width: 1460px)']: {
          margin: '0 20px 0 -45px'
        },
        [theme.breakpoints.down('lg')]: {
          margin: '0 10px 0 -32px'
        }
      },
      '&:after': {
        flex: 1,
        margin: '0 calc(-0.5 * (100vw - 1370px)) 0 20px',
        ['@media (max-width: 1460px)']: {
          margin: '0 -45px 0 20px'
        },
        [theme.breakpoints.down('lg')]: {
          margin: '0 -32px 0 20px'
        }
      },
      '&:hover, &$menuToggleWrapperMenuOpen': {
        '&:before, &:after': {
          borderBottom: `2px solid ${theme.palette.presentation.pageBorder.hover}`
        },
        '& $pageInfo': {
          color: theme.palette.presentation.pageBorder.hover,
          fontWeight: 500
        },
        '& $menuToggle': {
          color: theme.palette.presentation.pageBorder.hover,
          borderColor: theme.palette.presentation.pageBorder.hover,
          borderWidth: '2px',
          borderStyle: 'solid',
          backgroundColor: theme.palette.grey['100']
        }
      }
    },
    menuToggleWrapperMenuOpen: {
      // needed for above
    },
    pageInfo: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      '& svg': {
        fontSize: '1rem'
      }
    },
    menuToggle: (props) => ({
      position: 'absolute',
      left: 0,
      right: 0,
      margin: '0 auto',
      width: 'fit-content',
      maxWidth: 34,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      borderStyle: 'dashed'
    }),
    pageTitle: {
      position: 'relative',
      top: -1
    },
    navIcon: {
      marginLeft: theme.spacing(1)
    },
    editIcon: {
      marginRight: theme.spacing(1)
    },
    deleteIcon: {
      '& svg': {
        color: 'red'
      },
      '&:hover': {
        '& svg': {
          fill: 'red'
        }
      }
    },
    tooltipPlacement: {
      marginBottom: 0,
      marginTop: 0
    }
  }),
  { name: 'PageMenu' }
)

export interface PageMenuProps {
  classes?: StyleClasses<typeof useStyles>
  component?: React.ReactElement
  index: number
  tooltipTitle?: string
}

function PageMenu(props: PageMenuProps) {
  const classes = useStyles(props)
  const { component, index, tooltipTitle } = props
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { setFieldValue, setValues, submitForm, values } = useFormikContext<PresentationOption>()
  const { onPresentationPageChange, onSidebarPageChange, setIsSaving, sidebarPage } = usePresentation()
  const pages = values.pages
  const endOfPresentation = index === -1
  const page = !endOfPresentation ? pages[index] : null
  const { openDialog, dismissDialog } = useDialogs()
  const { enqueueSnackbar } = useSnackbar()

  const copiedPage = window.sessionStorage.getItem('copiedPage')

  const handleEditPage = async (newPage: PresentationPage) => {
    setValues(updatePresentationPage({ newPage, presentation: values }))
    submitForm()
  }

  const handleAddPage = (newPage: PresentationPage) => {
    const updatedPages = cloneDeep(values.pages)
    const insertIndex = endOfPresentation ? updatedPages.length : index
    updatedPages.splice(insertIndex, 0, newPage)
    setFieldValue('pages', updatedPages)
    submitForm()
  }

  const handleAddNewPage = () => {
    // ev.stopPropagation()
    openDialog(PresentationPageSelectDialog, {
      onConfirm: (page: PresentationPage) => {
        handleAddPage(page)
        onPresentationPageChange(page.key)
        dismissDialog()
      },
      presentation: values,
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  const handleRenamePage = async (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
    openDialog(EditPageTemplateDialog, {
      page,
      isNew: false,
      onConfirm: async (newPage: PresentationPage) => {
        setIsSaving(true)
        dismissDialog()
        await handleEditPage(newPage)
        setIsSaving(false)
      },
      onCancel: dismissDialog
    })
  }

  const handleRemovePage = async (index: number) => {
    openDialog(ConfirmationDialog, {
      title: 'Confirm Remove Block',
      message: `This Block will be removed from the ${PRESENTATION_LABEL}.`,
      noLabel: 'Cancel',
      onConfirm: () => {
        setValues(deletePresentationPage({ pageIndex: index, presentation: values }))
        submitForm()
        dismissDialog()
      },
      onCancel: dismissDialog
    })
  }

  const handleCopyPage = (index: number) => {
    const copiedPage = values.pages[index]
    enqueueSnackbar('Block Copied', { variant: 'success' })
    window.sessionStorage.setItem('copiedPage', JSON.stringify(copiedPage))
  }

  const handlePastePage = (index: number) => {
    const insertIndex = endOfPresentation ? values.pages.length : index

    setValues(pastePresentationPage({ insertIndex, presentation: values, page: JSON.parse(copiedPage) }))
    submitForm()
  }

  const handleOpen = () => {
    if (sidebarPage !== 'pages') onSidebarPageChange('pages')
    setIsMenuOpen(true)
  }

  return (
    <PresEditorTooltip
      title={tooltipTitle}
      disableHoverListener={isMenuOpen}
      disableFocusListener={isMenuOpen}
      disableTouchListener={isMenuOpen}
      classes={{
        tooltipPlacementTop: classes.tooltipPlacement,
        tooltipPlacementBottom: classes.tooltipPlacement
      }}
      {...(!tooltipTitle && {
        open: false
      })}
    >
      <PopupMenu
        TransitionProps={{ onEnter: () => handleOpen(), onExit: () => setIsMenuOpen(false) }}
        component={
          component ?? (
            <div
              className={classnames(classes.menuToggleWrapper, {
                [classes.menuToggleWrapperMenuOpen]: isMenuOpen
              })}
            >
              <div className={classes.pageInfo}>
                {/* <EditOutlineIcon className={classes.editIcon} /> */}
                <BlocksIcon className={classes.editIcon} />
                <div className={classes.pageTitle}>
                  {endOfPresentation ? `End of ${PRESENTATION_LABEL}` : page.title}
                </div>
                {!page?.advanced.useForNav && !endOfPresentation && <NoFlagIcon className={classes.navIcon} />}
              </div>
              <AddButton
                buttonSize={'small'}
                id={`add-page-${index}`}
                className={classes.menuToggle}
                buttonVariant={'outlined'}
              />
            </div>
          )
        }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {[
          <MenuItem key={'add'} icon={AddIcon} onClick={handleAddNewPage}>
            Add Block Above
          </MenuItem>,
          ...(copiedPage && endOfPresentation
            ? [
                <MenuItem key={'divider'} disabled classes={{ root: classes.menuItemDivider }} />,
                <MenuItem key={'copy'} icon={PasteIcon} onClick={() => handlePastePage(index)}>
                  Paste Block
                </MenuItem>
              ]
            : []),
          ...(!endOfPresentation
            ? [
                <MenuItem key={'divider'} disabled classes={{ root: classes.menuItemDivider }} />,
                <MenuItem key={'rename'} icon={EditOutlineIcon} onClick={handleRenamePage}>
                  Rename
                </MenuItem>,
                <MenuItem key={'copy'} icon={FileCopyOutlinedIcon} onClick={() => handleCopyPage(index)}>
                  Copy Block
                </MenuItem>,
                ...(copiedPage
                  ? [
                      <MenuItem key={'copy'} icon={PasteIcon} onClick={() => handlePastePage(index)}>
                        Paste Block
                      </MenuItem>
                    ]
                  : []),
                <MenuItem
                  key={'delete'}
                  icon={DeleteOutlineOutlinedIcon}
                  classes={{ root: classes.deleteIcon }}
                  onClick={() => handleRemovePage(index)}
                  disabled={pages.length === 1}
                >
                  Delete Block
                </MenuItem>
              ]
            : [])
        ]}
      </PopupMenu>
    </PresEditorTooltip>
  )
}

export default PageMenu
