import type { PresentationOption, QuoteFile } from 'paintscout'
import { replaceSectionImages } from './replaceSectionImages'

export function replacePresentationImages(args: {
  presentation: PresentationOption
  images: QuoteFile[]
}): PresentationOption {
  const { presentation, images } = args
  let usedCount = 0
  let loopedImages = [...images]

  presentation.pages.forEach((page, pageIndex) => {
    page.sections.forEach((section, sectionIndex) => {
      // loop images if close to end
      // probably max 6 images per section
      while (usedCount + 6 >= loopedImages.length) {
        loopedImages = [...loopedImages, ...images]
      }

      const { section: newSection, imageCount } = replaceSectionImages({
        section,
        images: loopedImages.slice(usedCount)
      })

      presentation.pages[pageIndex].sections[sectionIndex] = newSection
      usedCount += imageCount
    })
  })

  return presentation
}
