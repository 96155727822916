import type { Auth0Client, LogoutOptions } from '@auth0/auth0-spa-js'
import type { Auth0Claims } from 'paintscout'
import React, { useContext, useMemo, useReducer, useEffect } from 'react'
import type { Auth0Session } from './util/session'

export interface AuthProviderProps {
  auth0Client: Auth0Client
  scope: string
  audience: string
  appUrl?: string
  /**
   * Is called before the user is redirected to the login page.
   * Use this to clear any local data that might exist.
   */
  onLogout?: () => any

  children: React.ReactNode | ((props: AuthContextValue) => React.ReactNode)
}

export interface AuthContextValue {
  auth0Client?: Auth0Client
  session?: Auth0Session
  isAuthenticating: boolean

  authenticate: (opts?: { ignoreCache?: boolean }) => Promise<Auth0Session | null>
  logout: (options?: LogoutOptions) => any
  handleRedirectCallback: () => Promise<Auth0Session>
}

const AuthContext = React.createContext<AuthContextValue>({} as any)

// Grab the session stored in localstorage
const getInitialSession = () => {
  const regex = new RegExp('^@@auth0spajs@@::.+::@@user@@$')
  let foundItem

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)
    if (regex.test(key)) {
      foundItem = localStorage.getItem(key)
      break
    }
  }

  if (foundItem) {
    const parsedItem = JSON.parse(foundItem)
    return parsedItem
  }
  return {}
}

export default function AuthProvider({ auth0Client, onLogout, scope, audience, children, appUrl }: AuthProviderProps) {
  const cache = getInitialSession()
  const claims = cache?.decodedToken?.claims
  const expired = !!claims?.exp && claims.exp * 1000 < Date.now()
  const session = !expired && claims ? getSessionFromClaims(claims) : null

  const [state, dispatch] = useReducer(reducer, {
    isAuthenticating: true,
    session
  })

  useEffect(() => {
    const initializeAuth = async () => {
      const claims = (await auth0Client.getIdTokenClaims()) as unknown as Auth0Claims
      const expired = !!claims?.exp && claims.exp * 1000 < Date.now()
      const session = !expired ? getSessionFromClaims(claims) : null

      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticating: !session,
          session
        }
      })
    }

    initializeAuth()
  }, [])

  // logout when another tab logs out
  useEffect(() => {
    function listener(event: StorageEvent) {
      if (event.storageArea != localStorage) return

      if ((event.key || '').startsWith('@@auth0')) {
        // another window/tab logged in/out
        if (!event.newValue || !event.oldValue) {
          window.onbeforeunload = () => null // prevent unsaved changes alert from stopping reload, they cant save anyway
          window.location.reload()
          return
        }

        const oldUser = JSON.parse(event.oldValue)?.body?.decodedToken?.user?.sub
        const newUser = JSON.parse(event.newValue)?.body?.decodedToken?.user?.sub

        if (oldUser && newUser && oldUser !== newUser) {
          window.onbeforeunload = () => null
          window.location.reload()
          return
        }
      }
    }

    window.addEventListener('storage', listener)

    return () => {
      window.removeEventListener('storage', listener)
    }
  }, [])

  const value = useMemo<AuthContextValue>(
    () => ({
      ...state,
      auth0Client,
      authenticate: async ({ ignoreCache } = {}) => {
        dispatch({ type: 'AUTHENTICATING' })

        try {
          const cacheModeOff = ignoreCache || !state.session
          // this takes about 2s for a non-cache load. might have to do a useEffect to listen for a change in user in certain cases
          await auth0Client.getTokenSilently({
            cacheMode: cacheModeOff ? 'off' : 'on'
          })
          const claims = (await auth0Client.getIdTokenClaims()) as unknown as Auth0Claims
          const session = getSessionFromClaims(claims)
          dispatch({ type: 'AUTHENTICATED', payload: session })

          return session
        } catch (e) {
          if (e instanceof ProgressEvent) {
            return null
          }

          throw e
        }
      },
      logout: (opts) => {
        auth0Client.logout({ logoutParams: { federated: true, returnTo: appUrl, ...opts } })
        onLogout?.()
        dispatch({ type: 'LOGOUT' })
      },
      handleRedirectCallback: async () => {
        await auth0Client.handleRedirectCallback()
        const claims = (await auth0Client.getIdTokenClaims()) as unknown as Auth0Claims
        const session = getSessionFromClaims(claims)
        dispatch({ type: 'AUTHENTICATED', payload: session })

        return session
      }
    }),
    [auth0Client, state, audience, scope, onLogout, appUrl]
  )

  return (
    <AuthContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </AuthContext.Provider>
  )
}

interface AuthProviderState {
  isAuthenticating: boolean
  session?: Auth0Session
}

function reducer(state: AuthProviderState, action: { type: string; payload?: any }): AuthProviderState {
  const { type, payload } = action

  switch (type) {
    case 'AUTHENTICATING':
      // console.log('AUTHENTICATING')
      return {
        ...state,
        isAuthenticating: true
      }
    case 'AUTHENTICATED':
      // console.log('AUTHENTICATED', payload)
      return {
        ...state,
        isAuthenticating: false,
        session: payload
      }
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticating: false
        // keep session so we don't get unexpected UI change before <Authorizer> processes the redirect
      }
    case 'UPDATE_SESSION':
      // console.log('UPDATE_SESSION', payload)
      return {
        ...state,
        session: payload
      }
    case 'INITIALIZE':
      // console.log('INITIALIZE', payload)
      return {
        ...state,
        isAuthenticating: payload.isAuthenticating,
        session: payload.session
      }
  }

  return state
}

function getSessionFromClaims(claims: Auth0Claims): Auth0Session {
  if (!claims) {
    return null
  }

  return {
    user_id: claims.sub,
    email: claims.email,
    name: claims.name,
    picture: claims.picture,
    user_metadata: claims[`https://stickybid.com/user_metadata`],
    app_metadata: claims[`https://stickybid.com/app_metadata`],
    issued: claims.iat,
    expires: claims.exp
  }
}

/** Context consumers */
export const useAuth = () => useContext(AuthContext)
